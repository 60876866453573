.body a {
  color: #1737a0 !important;
}

.footer a {
  color: #1737a0 !important;
}

.body {
  background-color: #92bfb9;
  min-height: 90vh;
}

.navbar {
 /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  Add a shadow */
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #92bfb9;
  display: flex; /* Make the navbar a flex container */
  justify-content: center; /* Center the items horizontally */
}

.navbar-nav {
  display: flex; /* Make the navigation items a flex container */
  justify-content: center; /* Center the navigation items horizontally */
}